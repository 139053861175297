import React from 'react'
import { AUTH_PREFIX_PATH, DASHBOARD_PREFIX_PATH } from 'configs/AppConfig'

export const openRoutes = [
    {
        key: 'auth_url',
        path: `/auth_url/*`,
        component: React.lazy(() => import('views/auth-from-url')),
    },
    {
        key: 'find-agents',
        path: `/find-agents`,
        component: React.lazy(() => import('views/public-views/pages/find-agents')),
    },
    {
        key: 'agents-list',
        path: `/agents-list`,
        component: React.lazy(() => import('views/public-views/pages/agents-list')),
    },
    {
        key: 'single-agent',
        path: `/single-agent`,
        component: React.lazy(() => import('views/public-views/pages/single-agent')),
    },
    {
        key: 'property-detail',
        path: `/property-detail`,
        component: React.lazy(() => import('views/public-views/pages/property-detail')),
    },
    {
        key: 'find-properties',
        path: `/find-properties`,
        component: React.lazy(() => import('views/public-views/pages/properties')),
    },
    {
        key: 'properties-list',
        path: `/properties-list`,
        component: React.lazy(() => import('views/public-views/pages/properties-list')),
    },
    {
        key: 'about-us',
        path: `/about-us`,
        component: React.lazy(() => import('views/public-views/pages/about-us')),
    },
    {
        key: 'contact-us',
        path: `/contact-us`,
        component: React.lazy(() => import('views/public-views/pages/contact-us')),
    },
]


export const publicRoutes = [

    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'login-1',
        path: `${AUTH_PREFIX_PATH}/login-1`,
        component: React.lazy(() => import('views/auth-views/authentication/login-1')),
    },
    {
        key: 'login-2',
        path: `${AUTH_PREFIX_PATH}/login-2`,
        component: React.lazy(() => import('views/auth-views/authentication/login-2')),
    },
    {
        key: 'register-1',
        path: `${AUTH_PREFIX_PATH}/register-1`,
        component: React.lazy(() => import('views/auth-views/authentication/register-1')),
    },
    {
        key: 'register-2',
        path: `${AUTH_PREFIX_PATH}/register-2`,
        component: React.lazy(() => import('views/auth-views/authentication/register-2')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'error-page-1',
        path: `${AUTH_PREFIX_PATH}/error-page-1`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
    },
    {
        key: 'error-page-2',
        path: `${AUTH_PREFIX_PATH}/error-page-2`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-2')),
    },
]

export const protectedRoutes = [
    {
        key: 'upgrade-renew',
        path: `${DASHBOARD_PREFIX_PATH}/upgrade-renew`,
        component: React.lazy(() => import('views/dashboard-views/upgradeRenewPackage')),
        permissions: ['agent', 'agency', 'sub_agent'],
    },
    {
        key: 'dashboard',
        path: `${DASHBOARD_PREFIX_PATH}/`,
        component: React.lazy(() => import('views/dashboard-views/dashboard')),
        permissions: ['agent', 'agency','sub_agent','admin','user'],
    },
    {
        key: 'dashboard.profile',
        path: `${DASHBOARD_PREFIX_PATH}/profile`,
        component: React.lazy(() => import('views/dashboard-views/profile')),
        permissions: ['agent', 'agency','sub_agent','admin','user'],
    },
    {
        key: 'dashboard.requests.profile',
        path: `${DASHBOARD_PREFIX_PATH}/requests/profile`,
        component: React.lazy(() => import('views/dashboard-views/requests/ProfileRequests')),
        permissions: ['agent', 'agency','sub_agent','admin','user'],
    },
    {
        key: 'dashboard.requests.package',
        path: `${DASHBOARD_PREFIX_PATH}/requests/package`,
        component: React.lazy(() => import('views/dashboard-views/requests/PackageRequests')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.requests.profile',
        path: `${DASHBOARD_PREFIX_PATH}/requests/profile/*`,
        component: React.lazy(() => import('views/dashboard-views/profile/ProfileForm/ProfileInformation')),
        permissions: ['admin'],
    },

    {
        key: 'dashboard.packages',
        path: `${DASHBOARD_PREFIX_PATH}/packages`,
        component: React.lazy(() => import('views/dashboard-views/packages')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.packages.add-package',
        path: `${DASHBOARD_PREFIX_PATH}/packages/add-package`,
        component: React.lazy(() => import('views/dashboard-views/packages/PackageForm')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.packages.edit-package',
        path: `${DASHBOARD_PREFIX_PATH}/packages/edit-package/:id`,
        component: React.lazy(() => import('views/dashboard-views/packages/PackageForm')),
        permissions: ['admin'],
    },
    // {
    //     key: 'dashboard.packages.view-package',
    //     path: `${DASHBOARD_PREFIX_PATH}/packages/view-package/:id`,
    //     component: React.lazy(() => import('views/dashboard-views/packages/PackageForm')),
    // },
    {
        key: 'dashboard.listings',
        path: `${DASHBOARD_PREFIX_PATH}/listings`,
        component: React.lazy(() => import('views/dashboard-views/listings')),
        permissions: ['agent', 'agency','sub_agent'],
    },
    {
        key: 'dashboard.listings.add-listing',
        path: `${DASHBOARD_PREFIX_PATH}/listings/add-listing`,
        component: React.lazy(() => import('views/dashboard-views/listings/ListingForm')),
        permissions: ['agent', 'agency','sub_agent'],
    },
    {
        key: 'dashboard.listings.edit-listing',
        path: `${DASHBOARD_PREFIX_PATH}/listings/edit-listing/:id`,
        component: React.lazy(() => import('views/dashboard-views/listings/ListingForm')),
        permissions: ['agent', 'agency','sub_agent'],
    },
    {
        key: 'dashboard.listing.addType',
        path: `${DASHBOARD_PREFIX_PATH}/listing/add-type`,
        component: React.lazy(() => import('views/dashboard-views/addType')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.specializations',
        path: `${DASHBOARD_PREFIX_PATH}/specializations`,
        component: React.lazy(() => import('views/dashboard-views/specializations')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.projects',
        path: `${DASHBOARD_PREFIX_PATH}/projects`,
        component: React.lazy(() => import('views/dashboard-views/projects')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.addresses',
        path: `${DASHBOARD_PREFIX_PATH}/addresses`,
        component: React.lazy(() => import('views/dashboard-views/addresses')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.agentAgency',
        path: `${DASHBOARD_PREFIX_PATH}/agent-agency`,
        component: React.lazy(() => import('views/dashboard-views/agentAgency')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.users',
        path: `${DASHBOARD_PREFIX_PATH}/users`,
        component: React.lazy(() => import('views/dashboard-views/users')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.subAgents',
        path: `${DASHBOARD_PREFIX_PATH}/sub-agents`,
        component: React.lazy(() => import('views/dashboard-views/subAgents')),
        permissions: ['agency'],
    },
    {
        key: 'dashboard.add-subagent',
        path: `${DASHBOARD_PREFIX_PATH}/add-subagent`,
        component: React.lazy(() => import('views/dashboard-views/subAgents/SubAgentForm')),
        permissions: ['agency'],
    },
    {
        key: 'dashboard.edit-subagent',
        path: `${DASHBOARD_PREFIX_PATH}/edit-subagent/:id`,
        component: React.lazy(() => import('views/dashboard-views/subAgents/SubAgentForm')),
        permissions: ['agency'],
    },
    {
        key: 'dashboard.badges',
        path: `${DASHBOARD_PREFIX_PATH}/badges`,
        component: React.lazy(() => import('views/dashboard-views/badges')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.recommendations',
        path: `${DASHBOARD_PREFIX_PATH}/recommendations`,
        component: React.lazy(() => import('views/dashboard-views/recommendations')),
        permissions: ['agent', 'agency','sub_agent','user'],
    },
    {
        key: 'dashboard.ratings',
        path: `${DASHBOARD_PREFIX_PATH}/ratings`,
        component: React.lazy(() => import('views/dashboard-views/ratings')),
        permissions: ['agent', 'agency','sub_agent','user'],
    },
    {
        key: 'dashboard.statusStory',
        path: `${DASHBOARD_PREFIX_PATH}/status-story`,
        component: React.lazy(() => import('views/dashboard-views/statusStory')),
        permissions: ['agent', 'agency','sub_agent'],
    },
    {
        key: 'dashboard.settings',
        path: `${DASHBOARD_PREFIX_PATH}/settings`,
        component: React.lazy(() => import('views/dashboard-views/settings')),
        permissions: ['agent', 'agency','sub_agent','admin'],
    },
    // {
    //     key: 'dashboard.sales',
    //     path: `${DASHBOARD_PREFIX_PATH}/dashboards/sales`,
    //     component: React.lazy(() => import('views/app-views/dashboards/sales')),
    // },
    // {
    //     key: 'apps',
    //     path: `${DASHBOARD_PREFIX_PATH}/apps`,
    //     component: React.lazy(() => import('views/app-views/apps')),
    // },
    // {
    //     key: 'apps.mail',
    //     path: `${DASHBOARD_PREFIX_PATH}/apps/mail/*`,
    //     component: React.lazy(() => import('views/app-views/apps/mail')),
    // },
    // {
    //     key: 'apps.chat',
    //     path: `${DASHBOARD_PREFIX_PATH}/apps/chat/*`,
    //     component: React.lazy(() => import('views/app-views/apps/chat')),
    // },
    // {
    //     key: 'apps.calendar',
    //     path: `${DASHBOARD_PREFIX_PATH}/apps/calendar`,
    //     component: React.lazy(() => import('views/app-views/apps/calendar')),
    // },
    // {
    //     key: 'apps.project',
    //     path: `${DASHBOARD_PREFIX_PATH}/apps/project`,
    //     component: React.lazy(() => import('views/app-views/apps/project')),
    // },
    // {
    //     key: 'apps.project.list',
    //     path: `${DASHBOARD_PREFIX_PATH}/apps/project/list`,
    //     component: React.lazy(() => import('views/app-views/apps/project/project-list/ProjectList')),
    // },
    // {
    //     key: 'apps.project.scrumboard',
    //     path: `${DASHBOARD_PREFIX_PATH}/apps/project/scrumboard`,
    //     component: React.lazy(() => import('views/app-views/apps/project/scrumboard')),
    // },
    // {
    //     key: 'apps.ecommerce',
    //     path: `${DASHBOARD_PREFIX_PATH}/apps/ecommerce`,
    //     component: React.lazy(() => import('views/app-views/apps/e-commerce')),
    // },
    // {
    //     key: 'apps.ecommerce.add-product',
    //     path: `${DASHBOARD_PREFIX_PATH}/apps/ecommerce/add-product`,
    //     component: React.lazy(() => import('views/app-views/apps/e-commerce/add-product')),
    // },
    // {
    //     key: 'apps.ecommerce.edit-product',
    //     path: `${DASHBOARD_PREFIX_PATH}/apps/ecommerce/edit-product/:id`,
    //     component: React.lazy(() => import('views/app-views/apps/e-commerce/edit-product')),
    // },
    // {
    //     key: 'apps.ecommerce.product-list',
    //     path: `${DASHBOARD_PREFIX_PATH}/apps/ecommerce/product-list`,
    //     component: React.lazy(() => import('views/app-views/apps/e-commerce/product-list')),
    // },
    // {
    //     key: 'apps.ecommerce.orders',
    //     path: `${DASHBOARD_PREFIX_PATH}/apps/ecommerce/orders`,
    //     component: React.lazy(() => import('views/app-views/apps/e-commerce/orders')),
    // },
    // {
    //     key: 'components.general',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/general`,
    //     component: React.lazy(() => import('views/app-views/components/general')),
    // },
    // {
    //     key: 'components.general.button',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/general/button`,
    //     component: React.lazy(() => import('views/app-views/components/general/button')),
    // },
    // {
    //     key: 'components.general.icon',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/general/icon`,
    //     component: React.lazy(() => import('views/app-views/components/general/icon')),
    // },
    // {
    //     key: 'components.general.typography',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/general/typography`,
    //     component: React.lazy(() => import('views/app-views/components/general/typography')),
    // },
    // {
    //     key: 'components.general',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/layout`,
    //     component: React.lazy(() => import('views/app-views/components/layout')),
    // },
    // {
    //     key: 'components.general.grid',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/layout/grid`,
    //     component: React.lazy(() => import('views/app-views/components/layout/grid')),
    // },
    // {
    //     key: 'components.general.layout',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/layout/layout`,
    //     component: React.lazy(() => import('views/app-views/components/layout/layout')),
    // },
    // {
    //     key: 'components.navigation',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/navigation`,
    //     component: React.lazy(() => import('views/app-views/components/navigation')),
    // },
    // {
    //     key: 'components.navigation.affix',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/navigation/affix`,
    //     component: React.lazy(() => import('views/app-views/components/navigation/affix')),
    // },
    // {
    //     key: 'components.navigation.breadcrumb',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/navigation/breadcrumb`,
    //     component: React.lazy(() => import('views/app-views/components/navigation/breadcrumb')),
    // },
    // {
    //     key: 'components.navigation.dropdown',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/navigation/dropdown`,
    //     component: React.lazy(() => import('views/app-views/components/navigation/dropdown')),
    // },
    // {
    //     key: 'components.navigation.menu',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/navigation/menu`,
    //     component: React.lazy(() => import('views/app-views/components/navigation/menu')),
    // },
    // {
    //     key: 'components.navigation.pagination',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/navigation/pagination`,
    //     component: React.lazy(() => import('views/app-views/components/navigation/pagination')),
    // },
    // {
    //     key: 'components.navigation.steps',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/navigation/steps`,
    //     component: React.lazy(() => import('views/app-views/components/navigation/steps')),
    // },
    // {
    //     key: 'components.data-entry',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-entry`,
    //     component: React.lazy(() => import('views/app-views/components/data-entry')),
    // },
    // {
    //     key: 'components.data-entry.auto-complete',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-entry/auto-complete`,
    //     component: React.lazy(() => import('views/app-views/components/data-entry/auto-complete')),
    // },
    // {
    //     key: 'components.data-entry.cascader',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-entry/cascader`,
    //     component: React.lazy(() => import('views/app-views/components/data-entry/cascader')),
    // },
    // {
    //     key: 'components.data-entry.checkbox',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-entry/checkbox`,
    //     component: React.lazy(() => import('views/app-views/components/data-entry/checkbox')),
    // },
    // {
    //     key: 'components.data-entry.date-picker',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-entry/date-picker`,
    //     component: React.lazy(() => import('views/app-views/components/data-entry/date-picker')),
    // },
    // {
    //     key: 'components.data-entry.form',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-entry/form`,
    //     component: React.lazy(() => import('views/app-views/components/data-entry/form')),
    // },
    // {
    //     key: 'components.data-entry.input',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-entry/input`,
    //     component: React.lazy(() => import('views/app-views/components/data-entry/input')),
    // },
    // {
    //     key: 'components.data-entry.input-number',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-entry/input-number`,
    //     component: React.lazy(() => import('views/app-views/components/data-entry/input-number')),
    // },
    // {
    //     key: 'components.data-entry.mentions',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-entry/mentions`,
    //     component: React.lazy(() => import('views/app-views/components/data-entry/mentions')),
    // },
    // {
    //     key: 'components.data-entry.radio',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-entry/radio`,
    //     component: React.lazy(() => import('views/app-views/components/data-entry/radio')),
    // },
    // {
    //     key: 'components.data-entry.rate',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-entry/rate`,
    //     component: React.lazy(() => import('views/app-views/components/data-entry/rate')),
    // },
    // {
    //     key: 'components.data-entry.select',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-entry/select`,
    //     component: React.lazy(() => import('views/app-views/components/data-entry/select')),
    // },
    // {
    //     key: 'components.data-entry.slider',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-entry/slider`,
    //     component: React.lazy(() => import('views/app-views/components/data-entry/slider')),
    // },
    // {
    //     key: 'components.data-entry.switch',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-entry/switch`,
    //     component: React.lazy(() => import('views/app-views/components/data-entry/switch')),
    // },
    // {
    //     key: 'components.data-entry.time-picker',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-entry/time-picker`,
    //     component: React.lazy(() => import('views/app-views/components/data-entry/time-picker')),
    // },
    // {
    //     key: 'components.data-entry.transfer',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-entry/transfer`,
    //     component: React.lazy(() => import('views/app-views/components/data-entry/transfer')),
    // },
    // {
    //     key: 'components.data-entry.tree-select',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-entry/tree-select`,
    //     component: React.lazy(() => import('views/app-views/components/data-entry/tree-select')),
    // },
    // {
    //     key: 'components.data-entry.upload',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-entry/upload`,
    //     component: React.lazy(() => import('views/app-views/components/data-entry/upload')),
    // },
    // {
    //     key: 'components.data-display',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display`,
    //     component: React.lazy(() => import('views/app-views/components/data-display')),
    // },
    // {
    //     key: 'components.data-display.avatar',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display/avatar`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/avatar')),
    // },
    // {
    //     key: 'components.data-display.badge',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display/badge`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/badge')),
    // },
    // {
    //     key: 'components.data-display.calendar',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display/calendar`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/calendar')),
    // },
    // {
    //     key: 'components.data-display.card',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display/card`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/card')),
    // },
    // {
    //     key: 'components.data-display.carousel',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display/carousel`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/carousel')),
    // },
    // {
    //     key: 'components.data-display.collapse',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display/collapse`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/collapse')),
    // },
    // {
    //     key: 'components.data-display.comment',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display/comment`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/comment')),
    // },
    // {
    //     key: 'components.data-display.descriptions',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display/descriptions`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/descriptions')),
    // },
    // {
    //     key: 'components.data-display.empty',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display/empty`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/empty')),
    // },
    // {
    //     key: 'components.data-display.list',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display/list`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/list')),
    // },
    // {
    //     key: 'components.data-display.popover',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display/popover`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/popover')),
    // },
    // {
    //     key: 'components.data-display.statistic',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display/statistic`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/statistic')),
    // },
    // {
    //     key: 'components.data-display.table',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display/table`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/table')),
    // },
    // {
    //     key: 'components.data-display.tabs',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display/tabs`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/tabs')),
    // },
    // {
    //     key: 'components.data-display.tag',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display/tag`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/tag')),
    // },
    // {
    //     key: 'components.data-display.timeline',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display/timeline`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/timeline')),
    // },
    // {
    //     key: 'components.data-display.tooltip',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display/tooltip`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/tooltip')),
    // },
    // {
    //     key: 'components.data-display.tree',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/data-display/tree`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/tree')),
    // },
    // {
    //     key: 'components.feedback',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/feedback`,
    //     component: React.lazy(() => import('views/app-views/components/feedback')),
    // },
    // {
    //     key: 'components.feedback.alert',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/feedback/alert`,
    //     component: React.lazy(() => import('views/app-views/components/feedback/alert')),
    // },
    // {
    //     key: 'components.feedback.drawer',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/feedback/drawer`,
    //     component: React.lazy(() => import('views/app-views/components/feedback/drawer')),
    // },
    // {
    //     key: 'components.feedback.message',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/feedback/message`,
    //     component: React.lazy(() => import('views/app-views/components/feedback/message')),
    // },
    // {
    //     key: 'components.feedback.modal',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/feedback/modal`,
    //     component: React.lazy(() => import('views/app-views/components/feedback/modal')),
    // },
    // {
    //     key: 'components.feedback.notification',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/feedback/notification`,
    //     component: React.lazy(() => import('views/app-views/components/feedback/notification')),
    // },
    // {
    //     key: 'components.feedback.popconfirm',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/feedback/popconfirm`,
    //     component: React.lazy(() => import('views/app-views/components/feedback/popconfirm')),
    // },
    // {
    //     key: 'components.feedback.progress',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/feedback/progress`,
    //     component: React.lazy(() => import('views/app-views/components/feedback/progress')),
    // },
    // {
    //     key: 'components.feedback.result',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/feedback/result`,
    //     component: React.lazy(() => import('views/app-views/components/feedback/result')),
    // },
    // {
    //     key: 'components.feedback.skeleton',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/feedback/skeleton`,
    //     component: React.lazy(() => import('views/app-views/components/feedback/skeleton')),
    // },
    // {
    //     key: 'components.feedback.spin',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/feedback/spin`,
    //     component: React.lazy(() => import('views/app-views/components/feedback/spin')),
    // },
    // {
    //     key: 'components.other',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/other`,
    //     component: React.lazy(() => import('views/app-views/components/other')),
    // },
    // {
    //     key: 'components.other',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/other`,
    //     component: React.lazy(() => import('views/app-views/components/other')),
    // },
    // {
    //     key: 'components.other',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/other`,
    //     component: React.lazy(() => import('views/app-views/components/other')),
    // },
    // {
    //     key: 'components.other.anchor',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/other/anchor`,
    //     component: React.lazy(() => import('views/app-views/components/other/anchor')),
    // },
    // {
    //     key: 'components.other.backtop',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/other/backtop`,
    //     component: React.lazy(() => import('views/app-views/components/other/backtop')),
    // },
    // {
    //     key: 'components.other.config-provider',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/other/config-provider`,
    //     component: React.lazy(() => import('views/app-views/components/other/config-provider')),
    // },
    // {
    //     key: 'components.other.divider',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/other/divider`,
    //     component: React.lazy(() => import('views/app-views/components/other/divider')),
    // },
    // {
    //     key: 'components.chart',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/charts`,
    //     component: React.lazy(() => import('views/app-views/components/charts')),
    // },
    // {
    //     key: 'components.chart.apex-charts',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/charts/apex-charts`,
    //     component: React.lazy(() => import('views/app-views/components/charts/apex')),
    // },
    // {
    //     key: 'components.chart.chartjs',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/charts/chartjs`,
    //     component: React.lazy(() => import('views/app-views/components/charts/chartjs')),
    // },
    // {
    //     key: 'components.maps',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/maps`,
    //     component: React.lazy(() => import('views/app-views/components/maps')),
    // },
    // {
    //     key: 'components.maps.simple-map',
    //     path: `${DASHBOARD_PREFIX_PATH}/components/maps/simple-map`,
    //     component: React.lazy(() => import('views/app-views/components/maps/simple-map')),
    // },
    // {
    //     key: 'login-1',
    //     path: `${DASHBOARD_PREFIX_PATH}/login-1`,
    //     component: React.lazy(() => import('views/auth-views/authentication/login-1')),
    //     meta: {
    //         blankLayout: true
    //     }
    // },
    // {
    //     key: 'login-2',
    //     path: `${DASHBOARD_PREFIX_PATH}/login-2`,
    //     component: React.lazy(() => import('views/auth-views/authentication/login-2')),
    //     meta: {
    //         blankLayout: true
    //     }
    // },
    // {
    //     key: 'register-1',
    //     path: `${DASHBOARD_PREFIX_PATH}/register-1`,
    //     component: React.lazy(() => import('views/auth-views/authentication/register-1')),
    //     meta: {
    //         blankLayout: true
    //     }
    // },
    // {
    //     key: 'register-2',
    //     path: `${DASHBOARD_PREFIX_PATH}/register-2`,
    //     component: React.lazy(() => import('views/auth-views/authentication/register-2')),
    //     meta: {
    //         blankLayout: true
    //     }
    // },
    // {
    //     key: 'forgot-password',
    //     path: `${DASHBOARD_PREFIX_PATH}/forgot-password`,
    //     component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    //     meta: {
    //         blankLayout: true
    //     }
    // },
    // {
    //     key: 'error-page-1',
    //     path: `${DASHBOARD_PREFIX_PATH}/error-page-1`,
    //     component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
    //     meta: {
    //         blankLayout: true
    //     }
    // },
    // {
    //     key: 'error-page-2',
    //     path: `${DASHBOARD_PREFIX_PATH}/error-page-2`,
    //     component: React.lazy(() => import('views/auth-views/errors/error-page-2')),
    //     meta: {
    //         blankLayout: true
    //     }
    // },
    // {
    //     key: 'pages',
    //     path: `${DASHBOARD_PREFIX_PATH}/pages`,
    //     component: React.lazy(() => import('views/app-views/pages')),
    // },
    // {
    //     key: 'pages.profile',
    //     path: `${DASHBOARD_PREFIX_PATH}/pages/profile`,
    //     component: React.lazy(() => import('views/app-views/pages/profile')),
    // },
    // {
    //     key: 'pages.invoice',
    //     path: `${DASHBOARD_PREFIX_PATH}/pages/invoice`,
    //     component: React.lazy(() => import('views/app-views/pages/invoice')),
    // },
    // {
    //     key: 'pages.pricing',
    //     path: `${DASHBOARD_PREFIX_PATH}/pages/pricing`,
    //     component: React.lazy(() => import('views/app-views/pages/pricing')),
    // },
    // {
    //     key: 'pages.faq',
    //     path: `${DASHBOARD_PREFIX_PATH}/pages/faq`,
    //     component: React.lazy(() => import('views/app-views/pages/faq')),
    // },
    // {
    //     key: 'pages.setting',
    //     path: `${DASHBOARD_PREFIX_PATH}/pages/setting/*`,
    //     component: React.lazy(() => import('views/app-views/pages/setting')),
    // },
    // {
    //     key: 'pages.user-list',
    //     path: `${DASHBOARD_PREFIX_PATH}/pages/user-list`,
    //     component: React.lazy(() => import('views/app-views/pages/user-list')),
    // },
    // {
    //     key: 'docs.documentation',
    //     path: `${DASHBOARD_PREFIX_PATH}/docs/documentation/*`,
    //     component: React.lazy(() => import('views/app-views/docs')),
    // }
]