import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes as RouterRoutes, Route } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store from './store';
import history from './history'
import Layouts from './layouts'
import OpenLayouts from './layouts/open-layouts'
import AuthFromURL from './views/auth-from-url';
import { THEME_CONFIG } from './configs/AppConfig';
import axios from 'axios';
// import './lang'

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};
const currentUrl = window.location.href;

const isDashboard = currentUrl.includes('/dashboard');
const isAuthURL = currentUrl.includes('/auth_url');
const currentPathname = window.location.pathname;
const isRootUrl = currentPathname === '/';
if (isRootUrl) {
  window.location.href = '/find-agents';
}
// auth.js
export const logout = () => {
  localStorage.clear();
  window.location.href = '/find-agents';
};

function App() {
  useEffect(() => {
    if (isDashboard) {
      const interceptor = axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response && error.response.status === 401) {
            logout();
          }
          return Promise.reject(error);
        }
      );
      return () => {
        axios.interceptors.response.eject(interceptor);
      };
    }
  }, [isDashboard]);
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter history={history}>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            {/* {isDashboard ? <Layouts /> : <OpenLayouts />} */}
            {isDashboard? <Layouts /> : isAuthURL ? <AuthFromURL />: <OpenLayouts /> }

          </ThemeSwitcherProvider>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
