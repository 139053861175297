import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Maakansaz';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const SERVER_URL = env.SERVER_URL
export const S3_AWS_IMAGES_PUBLIC__BASE_URL = env.S3_AWS_IMAGES_PUBLIC_URL
export const S3_AWS_IMAGES_PRIVATE__BASE_URL = env.S3_AWS_IMAGES_PRIVATE_URL
export const GOOGLE_CLIENT_ID = env.CLIENT_ID
export const DASHBOARD_PREFIX_PATH = '/dashboard';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect'
export const AUTHENTICATED_ENTRY = `${DASHBOARD_PREFIX_PATH}/`;
export const UNAUTHENTICATED_ENTRY = '/find-agents'

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	blankLayout: false,
	reloadMenu: false,
};
