import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom'
import { useSelector } from 'react-redux';
import { 
	AUTH_PREFIX_PATH, 
	UNAUTHENTICATED_ENTRY, 
	REDIRECT_URL_KEY 
} from 'configs/AppConfig'

const ProtectedRoute = () => {
	const { token } = useSelector(state => state.auth)
	//const location = useLocation()

	// if (!token) {
	// //	return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
	// //	return <Navigate to={`${UNAUTHENTICATED_ENTRY}`} replace />;
	// 	return <Navigate to="/find-agents"/>;
	// 	window.location.reload();
	// }

	// return <Outlet />
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate('/find-agents', { replace: true });
			window.location.reload();
        }
    }, [token, navigate]);

    return token ? <Outlet /> : null;
}

export default ProtectedRoute