const dev = {
  S3_AWS_IMAGES_PUBLIC_URL:'https://makaansaz.s3.ap-south-1.amazonaws.com/public',
  S3_AWS_IMAGES_PRIVATE_URL:'https://makaansaz.s3.ap-south-1.amazonaws.com/private',
  API_ENDPOINT_URL: 'https://api.makaansaz.com/api',
  SERVER_URL: 'https://api.makaansaz.com',
  CLIENT_ID: '873492114910-n5q49ujigfplvg01vrjehcop4luaqdg1.apps.googleusercontent.com'
};

const prod = {
	S3_AWS_IMAGES_PUBLIC_URL:'https://makaansaz.s3.ap-south-1.amazonaws.com/public',
	S3_AWS_IMAGES_PRIVATE_URL:'https://makaansaz.s3.ap-south-1.amazonaws.com/private',
	API_ENDPOINT_URL: 'https://api.makaansaz.com/api',
	SERVER_URL: 'https://api.makaansaz.com',
	CLIENT_ID: '873492114910-n5q49ujigfplvg01vrjehcop4luaqdg1.apps.googleusercontent.com'
};

const test = {
	S3_AWS_IMAGES_PUBLIC_URL:'https://makaansaz.s3.ap-south-1.amazonaws.com/public',
	S3_AWS_IMAGES_PRIVATE_URL:'https://makaansaz.s3.ap-south-1.amazonaws.com/private',
	API_ENDPOINT_URL: 'https://api.makaansaz.com/api',
	SERVER_URL: 'https://api.makaansaz.com',
	CLIENT_ID: '873492114910-n5q49ujigfplvg01vrjehcop4luaqdg1.apps.googleusercontent.com'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
